import './App.scss';

import { NotificationCenter } from 'c2-common-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';

import { deleteNotification, selectNotifications } from '../../redux/notifications/notifications.slice';
import { getUserpilotApiKeyFromSession } from '../../utils/sessionUtils';
import SideNav from '../SideNav';

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector(selectNotifications);
  const onDeleteNotification = (index: number) => dispatch(deleteNotification(index));

  useEffect(() => {
    const userpilotApiKey = getUserpilotApiKeyFromSession();

    if (userpilotApiKey) {
      Userpilot.reload();
    }
  }, [location]);

  return (
    <div className="shell">
      <NotificationCenter notifications={notifications} onHandleDeleteNotification={onDeleteNotification} />
      <SideNav />
      <main className="main-view__container">
        <Outlet />
      </main>
    </div>
  );
};

export default React.memo(Main);
