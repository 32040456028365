import * as Sentry from '@sentry/react';
import { SessionStorage } from 'c2-common-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { API_KEYS } from '../../constants/appVariables';
import { isApiUser } from '../../utils/user';
import ErrorFallback from '../ErrorFallback';
import ErrorStatus from '../ErrorStatus';
import {
  selectIsApiAdmin,
  selectIsApiUserOnly,
  selectIsFederatedAuthOnly,
  selectIsFederatedManager,
} from './../../redux/sessionUser/sessionUser.slice';
import ApiKeyManagement from './../ApiKeyManagement';
import Autoprovisioning from './../Autoprovisioning';
import Federation from './../Federation';
import UserAdmin from './../UserAdmin';
import { setupRolesOnLoad, setupUserOnLoad, setUserPilotOnLoad } from './app.helpers';
import Main from './Main';

const AppRoutes: React.FC = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const dispatch = useDispatch();
  const isApiAdmin = useSelector(selectIsApiAdmin);
  const isApiUserOnly = useSelector(selectIsApiUserOnly);
  const isFederatedAuthOnly = useSelector(selectIsFederatedAuthOnly);
  const isFederatedManager = useSelector(selectIsFederatedManager);

  const userRoles = JSON.parse(SessionStorage.getItem('rolesWithProducts'));

  useEffect(() => {
    try {
      setupUserOnLoad(dispatch);
      setupRolesOnLoad(dispatch);
      setIsAllowed(true);
      setUserPilotOnLoad();
    } catch (e) {
      // If user is not admin, they will be unauthorized.
      // If we use React Router in a lower level, it puts the unauthorized page
      // inside where the main window is. Did it at this top level with native
      // JS for that reason. Included the if because React.
      const currentUrl = window.location.href;

      if (!currentUrl.includes('staticViews')) {
        window.location.assign(`${currentUrl}staticViews/unauthorized.htm`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return isAllowed ? (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="/" element={<Main />}>
          {isApiUserOnly && <Route index element={<Navigate to={`/${API_KEYS}`} replace />} />}
          <Route
            index
            element={
              <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                <UserAdmin />
              </Sentry.ErrorBoundary>
            }
          />
          {isFederatedManager && (
            <Route
              path="/federation"
              element={
                <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                  <Federation />
                </Sentry.ErrorBoundary>
              }
            />
          )}
          {isFederatedManager && !isFederatedAuthOnly && (
            <Route
              path="/autoprovisioning"
              element={
                <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                  <Autoprovisioning />
                </Sentry.ErrorBoundary>
              }
            />
          )}
          {(isApiAdmin || isApiUserOnly || isApiUser(userRoles)) && (
            <Route
              path="/api_keys"
              element={
                <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                  <ApiKeyManagement />
                </Sentry.ErrorBoundary>
              }
            />
          )}
          <Route path="*" element={<ErrorStatus code={404} />} />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  ) : null;
};

export default AppRoutes;
