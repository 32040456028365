import * as Sentry from '@sentry/react';
import { SessionStorage } from 'c2-common-ui';
import { batch } from 'react-redux';
import { Userpilot } from 'userpilot';

import {
  setEmail,
  setId,
  setIsAmAdmin,
  setIsApiAdmin,
  setIsApiUserOnly,
  setIsBmAdmin,
  setIsFederated,
  setIsFederatedAuthOnly,
  setIsFederatedManager,
  setIsPmAdmin,
  setIsSuperAdmin,
  setIsXmAdmin,
  setName,
  setUserpoolId,
} from '../../redux/sessionUser/sessionUser.slice';
import { getAllowedRoles } from '../../redux/sessionUser/sessionUser.thunks';
import { DispatchThunk } from '../../redux/types';
import {
  getEmailFromSession,
  getFullNameFromSession,
  getIdFromSession,
  getRolesFromSession,
  getUserpilotApiKeyFromSession,
} from '../../utils/sessionUtils';
import {
  ROLE_ADMIN,
  ROLE_AM_ADMIN,
  ROLE_API_ADMIN,
  ROLE_API_USER,
  ROLE_BM_ADMIN,
  ROLE_FEDERATED_MANAGER,
  ROLE_PM_ADMIN,
  ROLE_XM_ADMIN,
} from './../../constants/appVariables';

export const setupUserOnLoad = (dispatch: DispatchThunk) => {
  batch(() => {
    dispatch(setEmail(SessionStorage.getItem('email')));
    dispatch(setIsFederated(Boolean(SessionStorage.getItem('isFederatedUser'))));
    dispatch(setIsFederatedAuthOnly(SessionStorage.getItem('fed_auth_only') === 'true'));
    dispatch(setId(SessionStorage.getItem('username')));
    dispatch(setName(SessionStorage.getItem('fullName')));
    dispatch(setUserpoolId(SessionStorage.getItem('userpool_id')));
  });

  Sentry.setUser({
    email: getEmailFromSession(),
    id: getIdFromSession(),
  });
};

export const setupRolesOnLoad = (dispatch: DispatchThunk) => {
  try {
    const roles: string[] = getRolesFromSession();

    if (roles.includes(ROLE_API_USER) && JSON.stringify(roles).indexOf(ROLE_ADMIN) === -1) {
      SessionStorage.setItem('isApiUserOnly', true);

      dispatch(setIsApiUserOnly(true));
    } else if (JSON.stringify(roles).indexOf(ROLE_ADMIN) > -1) {
      batch(() => {
        dispatch(setIsAmAdmin(roles.includes(ROLE_ADMIN) || roles.includes(ROLE_AM_ADMIN)));
        dispatch(setIsApiAdmin(roles.includes(ROLE_ADMIN) || roles.includes(ROLE_API_ADMIN)));
        dispatch(setIsBmAdmin(roles.includes(ROLE_ADMIN) || roles.includes(ROLE_BM_ADMIN)));
        dispatch(setIsPmAdmin(roles.includes(ROLE_ADMIN) || roles.includes(ROLE_PM_ADMIN)));
        dispatch(setIsXmAdmin(roles.includes(ROLE_ADMIN) || roles.includes(ROLE_XM_ADMIN)));
        dispatch(setIsSuperAdmin(roles.includes(ROLE_ADMIN)));
        dispatch(setIsFederatedManager(roles.includes(ROLE_FEDERATED_MANAGER)));
      });

      dispatch(getAllowedRoles());
    } else {
      Sentry.captureException('Somehing went wrong. Unable to access page...');
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const setUserPilotOnLoad = () => {
  const userpilotApiKey = getUserpilotApiKeyFromSession();

  if (userpilotApiKey) {
    try {
      Userpilot.identify(getIdFromSession(), {
        lastLogin: SessionStorage.getItem('lastLogin'),
        name: getFullNameFromSession(),
        email: getEmailFromSession(),
        role: getRolesFromSession().join(', '),
        company: {
          id: SessionStorage.getItem('userpool_id'),
          name: SessionStorage.getItem('tenantName'),
        },
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  }
};
